import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import React from "react";

// markup
const NavHeader1 = (props) => {
  const location = useLocation();
  const currentPath =
    location.pathname[location.pathname.length - 1] === "/" &&
    location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;

  return (
    <React.Fragment>
      <div
        className="bg-main-color-gradient"
        style={{
          borderBottomRightRadius: 9999,
          borderBottomLeftRadius: 9999,
          height: 218,
        }}
      >
        {props.header ? (
          props.header
        ) : (
          <div className="flex justify-between items-center px-4 pt-5 ">
            <span
              className="flex items-center cursor-pointer"
              onClick={(e) => {
                if (props.onBack) {
                  props.onBack();
                } else {
                  if (typeof window !== `undefined`) {
                    navigate(-1);
                  }
                }
              }}
            >
              <ArrowLeftOutlined className="mr-2" style={{ fontSize: 20 }} />
            </span>
            <span className="inline-block">{props.suffix}</span>
          </div>
        )}
      </div>
      {props.children}
    </React.Fragment>
  );
};

export default NavHeader1;
