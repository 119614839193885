import { useLocation } from "@reach/router";
import { Button, message } from "antd";
import copy from "copy-to-clipboard";
import get from "lodash.get";
import QrCode from "qrcode.react";
import React from "react";
import { connect } from "react-redux";
import { Textfit } from "react-textfit";
import NavHeader1 from "../../../components/general/components/NavHeader1";
import { Group_385Icon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { updateUser } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";

// markup
const InviteFriendPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  // const accessKey = get(props.user, "accessKey");

  const getShareUrl = (referralCode) => {
    return referralCode
      ? `${get(location, "host")}/register?referralCode=${referralCode}`
      : "";
  };
  return (
    <React.Fragment>
      <Layout>
        <NavHeader1
          suffix={
            <Textfit className="font-semibold text-right text-base">
              {/* 邀请好友 <MediaQuery maxWidth={440}><br/></MediaQuery> */}
              {t("inviteFriends", sourceKey.profile)}
            </Textfit>
          }
        >
          <div className="relative p-5" style={{ top: -140 }}>
            <div className="flex justify-center items-center ">
              <img src={Group_385Icon} style={{ width: 300, height: 170 }} />
            </div>
            <div className="flex justify-center items-center my-5">
              <span className="inline-block w-4/5 shadow-lg p-5 rounded-3xl">
                <div className="font-semibold mb-5 flex justify-center items-center">
                  {t("qrCode", sourceKey.profile)}
                </div>
                <div className="my-5 flex justify-center items-center">
                  <QrCode
                    value={getShareUrl(get(props.user, "user.referralCode"))}
                    size={130}
                  ></QrCode>
                </div>
                <Textfit className="mt-5 mb-2 flex justify-center items-center font-semibold text-lg">
                  {get(props.user, "user.username")}
                </Textfit>
                <div className="text-sm flex items-center justify-center">
                  ID: {get(props.user, "user.referralCode")}
                </div>
              </span>
            </div>
            <Button
              className="main-button-color input-border"
              block
              onClick={(e) => {
                copy(getShareUrl(get(props.user, "user.referralCode")));
                message.success(
                  <React.Fragment>
                    {/* 已复制分享链接 <br /> */}
                    {t("copiedLink", sourceKey.profile)}
                  </React.Fragment>
                );
              }}
            >
              {/* 复制分享链接  */}
              {t("copyLink", sourceKey.profile)}
            </Button>
          </div>
        </NavHeader1>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendPage);
